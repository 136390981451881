import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import LegacyName from './LegacyName'


const LegacyActiveHouseholdUser = props => (
  <div className='active-household-user'>
    <LegacyName client={props.client} />
    <div className='user-status'>
      <p>Status:
        <span className={classnames({active: !props.client.deceased_at})}> {props.client.deceased_at ? 'Deceased' : 'Active'}</span>
      </p>
      <p className='invite-status'>Invitation Accepted</p>
    </div>
    <div className='login-info'>
      <p>{props.client.login_count} Logins</p>
      <p className='last-login'>
          Last Login {props.client.last_login ? moment(props.client.last_login).format('MM/DD/YYYY') : 'Unknown'}
      </p>
    </div>
  </div>
)

LegacyActiveHouseholdUser.propTypes = {
  client: PropTypes.shape({
    deceased_at: PropTypes.string,
    last_login: PropTypes.string,
    login_count: PropTypes.number
  })
}

export default LegacyActiveHouseholdUser
