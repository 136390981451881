import {useRef, useState} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint, updateResource} from 'redux-json-api'

import {Cancel, Continue} from '../../../../../shared_components/ButtonTypes'
import Modal from '../../../../../shared_components/Modal'
import modalWrapper from '../../../../../shared_components/ModalWrapper'
import Forms from '../../../../../shared_components/forms'
import {Icon} from '../../../../../shared_components/Icon'
import Container from '../../../../../lib/Container'
import ClientActions from '../../../actions/ClientActions'
import bindUpdate from '../../../../../shared_components/BoundUpdate'
import ShadowWrapper from '../../shared_firm/ShadowWrapper'
import storePrototype from '../../../../../shared_components/StorePrototype'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import {goToInviteClientModal} from '../../../../../lib/navigation/proNavigation'

import {sendClientInvitation, notifyAfterSend} from '../../../../../lib/clientInviteTools'
import {useEverplanData} from '../../../../../lib/hooks'

import './addToHousehold.scss'


const AddToHousehold = (props, context) => {
  const addHouseholdStore = Container.getStore('add_household')
  const {refetchItemResponses} = useEverplanData(props.clientSeat.get('everplan-id'))

  const formRef = useRef()
  const [hasSubmitted, setHasSubmitted] = useState(false) // need this to keep the submit button from flickering between screens
  const {alwaysNotify} = useNotificationContext()

  const notifyAndRedirect = ({invitedClientSeat, message}) => {
    if (props.client.status === 'Active') {
      if (props.organization.get('organization-client-invite') === 'engagement_automation') {
        sendClientInvitation({
          ...props,
          clientSeat: Map({id: invitedClientSeat.client_seat_id, 'everplan-id': props.clientSeat.get('everplan-id')})
        }).then(() => {
          // getting **invitedClient** below 'cause **invitedClientSeat** does not have status on it and that's necessary in **notifyAfterSend** - Atanda
          const invitedClient = props.clients.find(client => client.client_seat_id === invitedClientSeat.client_seat_id)

          notifyAfterSend({alwaysNotify, client: invitedClient, existingClient: props.client})
        })
      } else {
        goToInviteClientModal({clientSeatId: props.client.client_seat_id, inviteSeatId: invitedClientSeat.client_seat_id})
      }
    }

    if (message)
      alwaysNotify.shortSuccess(message)
  }

  const fetchNeededClientInformation = ({everplanId, invitedClientSeat, householdData}) => {
    props.readEndpoint(`everplans/${everplanId}?include=firm-ownership.secondary-everplan-owner,client-seats`)
      .then(() => notifyAndRedirect({invitedClientSeat, message: householdData.message}))
  }

  const onHouseholdAdded = () => {
    setHasSubmitted(true)

    // Needed to use this hacky approach, due to the mistakes in the `updating()` method in the `BoundUpdate` factory,
    // which does not actually call this callback after updating the `data` props with the data of the store,
    // but rather right before. However, the store has this data already so just using that directly to get the new client seat id.
    ClientActions.fetchHousehold(props.client.client_seat_id)

    const householdData = addHouseholdStore.getState().data
    const everplanId = props.clientSeat.get('everplan-id')
    const invitedClientSeat = householdData.clients_added.find(client => client.client_seat_id !== props.client.client_seat_id)

    // Need to re-fetch all these v2 resources, because when an individual everplan becomes a household the ownerships, responses,
    // client-seats, and everplan need to be updated with the new information. The reason the `item-responses` do not need to be fetched again
    // if the invited client needs approval is because the responses don't get updated with new ownership information, until approval is granted.
    if (invitedClientSeat.status === 'Requesting Approval') {
      fetchNeededClientInformation({everplanId, invitedClientSeat, householdData})
    } else {
      props.readEndpoint(`everplans/${everplanId}?include=firm-ownership.secondary-everplan-owner,client-seats`)
        .then(() => refetchItemResponses({everplanId}))
        .then(() => notifyAndRedirect({invitedClientSeat, message: householdData.message}))
    }

    props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)
  }

  const addUserToExistingPlan = () => {
    const form = formRef.current

    if (form.validate().length === 0) {
      props.updating(onHouseholdAdded)
      ClientActions.addToExistingPlan(Object.assign({clientSeatId: props.client.client_seat_id}, form.formData()))
    }

    if (props.errors.length > 0)
      props.finished()
  }

  const closeModal = () => {
    context.closeModal()
    props.finished()
  }

  if (props.client.status === 'Declined' || props.client.deceased_at) {
    return null
  } else {
    return (
      <ShadowWrapper className='add-new-user'>
        <div className='add-to-household'>
          <div className='add-household-container' onClick={context.showModal}>
            <Icon className='fa-user-plus' />
          </div>
          <Modal visible={props.showModal} closer={closeModal}>
            <h2 className='add-title'>Add Household Member to This Everplan</h2>
            <Forms.Form ref={formRef}>
              <Forms.Input autoFocus label='First Name:' name='first_name' validator={Forms.Validators.required} />
              <Forms.Input label='Last Name:' name='last_name' validator={Forms.Validators.required} />
              <Forms.Input label='Email Address:' name='email' type='email' validator={Forms.Validators.emailValidator} />
              {props.errors && props.errors.length > 0 && <Forms.Errors errors={props.errors[0].errors} />}
            </Forms.Form>
            <div className='button-group'>
              <Cancel onClick={closeModal} />
              <Continue onClick={addUserToExistingPlan} loading={props.processing || hasSubmitted}>
                {props.client.status === 'Active' ? 'Continue »' : 'Add'}
              </Continue>
            </div>
          </Modal>
        </div>
      </ShadowWrapper>
    )
  }
}

AddToHousehold.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

AddToHousehold.propTypes = {
  finished: PropTypes.func,
  readEndpoint: PropTypes.func,
  clientSeat: PropTypes.instanceOf(Map),
  clients: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.array,
  processing: PropTypes.bool,
  updating: PropTypes.func,
  client: PropTypes.shape({
    client_seat_id: PropTypes.number,
    deceased_at: PropTypes.string,
    status: PropTypes.string
  }),
  organization: PropTypes.instanceOf(Map),
  showModal: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

Container.registerStore('add_household', storePrototype(ClientActions.Types.DID_ADD_TO_EXISTING_PLAN))

export const BoundAddToHousehold = modalWrapper(bindUpdate(AddToHousehold, 'add_household', 'email'))

export default connect(null, {readEndpoint, updateResource})(BoundAddToHousehold)
