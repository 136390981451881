import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {useState, useMemo} from 'react'
import {connect} from 'react-redux'
import {deleteResource, readEndpoint} from 'redux-json-api'

import {allOwnersActive} from '../../../../../lib/clientsEverplanStatus'
import {removedClientName, removedClientVerb} from '../../../../../lib/removeClientsTools'

import RemoveClient from './RemoveClient'
import routerUtils from '../../../../../lib/routerUtils'
import {currentUserConfig} from '../../../../../lib/userTools'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import {useEverplanData} from '../../../../../lib/hooks'

const RemoveClientController = props => {
  const everplanId = props.everplan.get('id')

  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()
  const {refetchItemResponses} = useEverplanData(everplanId)

  const activeOwners = useMemo(() => allOwnersActive(props.clientSeats), [props.clientSeats])

  const constructPayload = seatId => {
    if (activeOwners)
      return {type: 'client-households', id: props.firmOwnership.get('id')}
    else
      return {type: 'client-seats', id: seatId}
  }

  const removeClientFromPro = clientSeat => {
    setProcessing(true)

    const payload = constructPayload(clientSeat.get('id'))
    const {clientSeats} = props

    const isHousehold = props.everplan.get('is-household')

    props.deleteResource(payload).then(() => {
      props.readEndpoint(`client-stats/${props.userConfig.get('firm-id')}`)

      if (!activeOwners && isHousehold)
        refetchItemResponses({everplanId: parseInt(everplanId, 10)})

      alwaysNotify.longSuccess(`${removedClientName({clientSeat, clientSeats})} ${removedClientVerb(clientSeats)} been removed.`)
      routerUtils.push('pro/clients')
    })
  }

  return (
    <RemoveClient
      {...props}
      processing={processing}
      removeClientFromPro={removeClientFromPro}
    />
  )
}

RemoveClientController.propTypes = {
  everplan: PropTypes.instanceOf(Map),
  clientSeats: PropTypes.instanceOf(List),
  deleteResource: PropTypes.func,
  firmOwnership: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map),
  owners: PropTypes.instanceOf(List),
  readEndpoint: PropTypes.func
}

const mapStateToProps = ({api}) => ({userConfig: currentUserConfig(api)})

export default connect(mapStateToProps, {deleteResource, readEndpoint})(RemoveClientController)
