import React from 'react'

import PropTypes from 'prop-types'
import validate from 'validate.js'

import Button from '../../../../shared_components/Button'
import Forms from '../../../../shared_components/forms'
import {If, Else} from '../../../../shared_components/deprecated/IfFlag'
import HouseholdLearnMoreModal from './HouseholdLearnMoreModal'
import PrivacyLearnMore from './PrivacyLearnMore'

import ClientInviteActions from '../../actions/ClientInviteActions'
import {trimString} from '../../../../lib/textTools'

import './clientInviteForm.scss'

export default class ClientInviteForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: [],
      processing: false
    }
    this.emailValidator = this.emailValidator.bind(this)
    this.resetAndSubmit = this.resetAndSubmit.bind(this)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors.length > 0)
      this.setState({processing: false})
  }
  resetAndSubmit() {
    this.setState({processing: true})

    if (this.props.errors.length > 0)
      this.props.resetEmailError()

    const formErrors = this.refs.form.validate()

    if (formErrors.length > 0) {
      this.setState({formErrors})
      this.setState({processing: false})
    }

    this.formSubmit()
  }
  formSubmit() {
    const errors = this.refs.form.validate()

    if (errors.length === 0) {
      this.setState({formErrors: []})
      ClientInviteActions.submitActivationForm(this.refs.form.formData(), this.props.params.clientInviteId)
    }
  }
  emailValidator(name, data) {
    const error = validate.single((trimString(data.email)), {presence: true, email: true})

    if (error)
      return {name: 'email', errors: [`Email ${error}`]}
  }

  render() {
    const privacyText = `Your Everplan comes courtesy of ${this.props.clientInvite.advisor_name}. In order to help you progress in your planning, certain information about how you are using Everplans may be shared with ${this.props.clientInvite.advisor_name}.`
    return (
      <div className='client-invite-form row-wrapper-sb'>
        <div>
          <h1>Activate your Everplans account</h1>
          <Forms.Form ref='form' errors={this.props.errors}>
            <Forms.Input name='email' className='email' label='Email address' validator={this.emailValidator} defaultValue={this.props.clientInvite.email} readonly='' />
            <Forms.DateInput label='Date of birth' ref='dob' name='birthdate' validator={Forms.Validators.birthdateValidator} />
            <Forms.ZipOrCountry name='zipOrCountry' />
            <Forms.Password name='password' type='password' label='Password' />
            <Forms.ServiceTerms name='agreement_accepted' />
            <div className='button-group'>
              <Button loading={this.state.processing} onClick={this.resetAndSubmit} className='btn btn-primary' ref='button'>Activate account</Button>
              <If flag={this.state.formErrors.length > 0 || this.props.errors.length > 0}>
                <Forms.Errors errors={['Please review the errors above.']} />
              </If>
            </div>
          </Forms.Form>
        </div>
        <div className='privacy-box'>
          <div className='privacy-content'>
            <h4>Privacy notice{this.props.clientInvite.household ? 's' : ''}</h4>
            <If flag={!!this.props.clientInvite.household}>
              <ul className='privacy-blurbs'>
                <li>
                  {privacyText}
                  <PrivacyLearnMore data={this.props.clientInvite} />
                </li>
                <li>
                  Your subscription is for a Household Everplan, to be shared with {this.props.clientInvite.hp_partner_name}. {this.props.clientInvite.hp_partner_first_name} is a co-administrator, and will be able to view, edit, and manage all parts of the Everplan that you have been provided.
                  <HouseholdLearnMoreModal data={this.props.clientInvite} />
                </li>
              </ul>
            </If>
            <Else flag={!!this.props.clientInvite.household}>
              <p>{privacyText}</p>
              <PrivacyLearnMore data={this.props.clientInvite} />
            </Else>
          </div>
        </div>
      </div>
    )
  }
}

ClientInviteForm.propTypes = {
  clientInvite: PropTypes.shape({
    advisor_name: PropTypes.string,
    email: PropTypes.string,
    household: PropTypes.bool,
    hp_partner_first_name: PropTypes.string,
    hp_partner_name: PropTypes.string
  }),
  errors: PropTypes.arrayOf(PropTypes.string),
  params: PropTypes.shape({
    clientInviteId: PropTypes.string
  }),
  resetEmailError: PropTypes.func
}
