import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Component} from 'react'
import {readEndpoint} from 'redux-json-api'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import EditClientModal from './EditClientModal'
import modalWrapper from '../../../../shared_components/ModalWrapper'

import Container from '../../../../lib/Container'
import ClientActions from '../../actions/ClientActions'
import Modal from '../../../../shared_components/Modal'
import storePrototype from '../../../../shared_components/StorePrototype'

export class EditClientModalController extends Component {
  constructor(props) {
    super(props)

    this.state = {showInvitePrompt: false}
    this.closeModal = this.closeModal.bind(this)
    this.changePage = this.changePage.bind(this)
  }
  changePage(newValue) { this.setState({showInvitePrompt: newValue}) }
  closeModal() {
    this.context.closeModal()
    this.setState({showInvitePrompt: false})
  }

  render() {
    return (
      <span>
        <a onClick={this.context.showModal}>Edit name/email</a>
        <Modal visible={this.props.showModal}>
          <EditClientModal
            {...this.props}
            changePage={this.changePage}
            closeModal={this.closeModal}
            editClient={this.editClient}
            showInvitePrompt={this.state.showInvitePrompt}
            showModal={this.context.showModal}
          />
        </Modal>
      </span>
    )
  }
}

EditClientModalController.propTypes = {
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func,
  showModal: PropTypes.bool,
  editClientNotification: PropTypes.shape({
    data: PropTypes.object
  })
}

EditClientModalController.contextTypes = {
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func,
  showModal: PropTypes.func
}

Container.registerStore('editClient', storePrototype(ClientActions.Types.DID_UPDATE_CLIENT))
const boundEditClientModalController = modalWrapper(bindUpdate(EditClientModalController, 'editClient', 'email'))
export default connect(null, {readEndpoint})(boundEditClientModalController)
