import PropTypes from 'prop-types'

import {goToInviteClientModal} from '../../../../lib/navigation/proNavigation'
import EditPage from './EditPage'
import InvitePage from './InvitePage'


import './editClientModal.scss'

const EditClientModal = props => {
  const inviteClient = () => {
    props.closeModal()
    props.changePage(false)
    goToInviteClientModal({clientSeatId: props.clientSeatId, inviteSeatId: props.clientSeatId})
  }

  const sharedProps = {
    first_name: props.first_name,
    last_name: props.last_name,
    email: props.email,
    closeModal: props.closeModal,
    processing: props.processing
  }

  return (
    <section className='edit-client-modal'>
      {
        props.showInvitePrompt ?
          <InvitePage {...sharedProps} inviteClient={inviteClient} /> :
          <EditPage
            {...sharedProps}
            errors={props.errors}
            status={props.status}
            editClient={props.editClient}
            updating={props.updating}
            clientSeatId={props.clientSeatId}
            everplanId={props.everplanId}
            finished={props.finished}
            changePage={props.changePage}
            readEndpoint={props.readEndpoint}
          />
      }
    </section>
  )
}

EditClientModal.defaultProps = {
  errors: [],
  email: '',
  first_name: '',
  last_name: '',
  processing: false
}

EditClientModal.propTypes = {
  clientSeatId: PropTypes.number,
  changePage: PropTypes.func,
  closeModal: PropTypes.func,
  data: PropTypes.shape({
    clients: PropTypes.array
  }),
  editClient: PropTypes.func,
  everplanId: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  finished: PropTypes.func,
  processing: PropTypes.bool,
  readEndpoint: PropTypes.func,
  showModal: PropTypes.func,
  showInvitePrompt: PropTypes.bool,
  status: PropTypes.string,
  updating: PropTypes.func
}

export default EditClientModal
